import React from "react";
import cls from "./shared.module.scss"

function BlockHeaderCustom({title}: any) {
    // const name = props.title.props.defaultMessage;
    // const id = props.title.props.id;

    return (
        title ? <div className={cls["block-header"]}>
            <h2 className={cls["block-header__title__custom"]}>
                {/*<span className={cls["title-line-fms"]}></span>*/}
                <span className={cls["title-name-fms"]}>
                    {" "}
                    {/*<FormattedMessage id={id} defaultMessage={name}/>*/}
                    {title}
                </span>
                {/*<span className={cls["title-line-fms"]}></span>*/}
            </h2>
        </div> : null
    );
}

export default BlockHeaderCustom;
